'use client';

import { useSignIn } from '@clerk/nextjs';
import type { EmailLinkFactor } from '@clerk/types';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import GoogleIcon from '@/components/icons/GoogleIcon';
import { Input } from '@/components/inputs/Input';
import { Route } from '@/constants/routes';

interface SignInEmailFormProps {
  invitationTicket?: string | null;
}

const SignInEmailForm = ({ invitationTicket }: SignInEmailFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isLoaded: isSignInLoaded, signIn, setActive } = useSignIn();

  const router = useRouter();
  const { handleSubmit, setError, formState, register } = useForm<{
    identifier: string;
  }>({
    defaultValues: {
      identifier: '',
    },
  });

  const getBaseUrl = () => {
    if (typeof window !== 'undefined') {
      return `${window.location.origin}`;
    }
    return '';
  };

  const handleInitialSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    if (!isSignInLoaded) return;

    try {
      if (invitationTicket) {
        // Handle invitation sign-in
        const signInAttempt = await signIn.create({
          strategy: 'ticket',
          ticket: invitationTicket,
        });

        if (signInAttempt.status === 'complete') {
          await setActive({ session: signInAttempt.createdSessionId });
          router.push('/dashboard');
        }
      } else {
        // signIn.create auto-prepares verification for strategies like "email_code", so no need for `prepareFirstFactor`.
        const signInAttempt = await signIn.create({
          identifier: data.identifier.trim(),
          strategy: 'email_link',
          redirectUrl: `${getBaseUrl()}${Route.FactorOne}`,
        });

        const emailFactor = signInAttempt.supportedFirstFactors?.find(
          (factor): factor is EmailLinkFactor =>
            factor.strategy === 'email_link',
        );

        if (!emailFactor || !emailFactor.emailAddressId) {
          throw new Error('Email address not found.');
        }

        sessionStorage.setItem('email', data.identifier);
        sessionStorage.setItem('emailAddressId', emailFactor.emailAddressId);

        router.push(Route.FactorOne);
      }
    } catch (error: any) {
      if (error.errors && error.errors.length > 0) {
        setError('identifier', {
          type: 'manual',
          message:
            error.errors[0].longMessage || 'Sign-in failed. Please try again.',
        });
      } else {
        setError('identifier', {
          type: 'manual',
          message: error.message || 'An unexpected error occurred.',
        });
      }
    } finally {
      setIsLoading(false);
    }
  });

  const signInWithOAuth = (strategy: 'oauth_google') => {
    if (!isSignInLoaded) return;
    signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: '/sign-in/sso-callback',
      redirectUrlComplete: '/dashboard',
      ...(invitationTicket ? { ticket: invitationTicket } : {}),
    });
  };

  return (
    <form onSubmit={handleInitialSubmit} className="w-full space-y-8">
      <div className="space-y-2">
        <h2 className="text-2xl font-medium">
          {invitationTicket
            ? 'Welcome back'
            : 'Ready to connect advertisers and audience?'}
        </h2>
        <p className="text-stone-500">
          {invitationTicket
            ? 'Sign in to accept your invitation'
            : 'Enter your email to receive a sign-in link'}
        </p>
      </div>

      {!invitationTicket && (
        <Input
          {...register('identifier', { required: !invitationTicket })}
          label="Email Address"
          placeholder="Type here"
          errorMessage={formState.errors.identifier?.message}
        />
      )}

      <button
        type="submit"
        className={`btn btn-primary w-full bg-primary text-white !shadow-none hover:bg-blue-700 ${isLoading ? 'btn-disabled' : ''}`}
        disabled={isLoading}
      >
        {isLoading && (
          <span
            data-testid="loading-testid"
            className="loading loading-spinner"
          />
        )}
        {!isLoading &&
          (invitationTicket ? 'Continue with Email' : 'Send Sign-In Link')}
      </button>

      <div className="text-center text-stone-300">or</div>

      <button
        type="button"
        onClick={() => signInWithOAuth('oauth_google')}
        className="btn btn-outline btn-primary flex w-full"
      >
        <GoogleIcon />
        Sign In with Google
      </button>

      <div className="flex gap-2">
        <p className="text-sm text-neutral-500">Don’t have an account?</p>
        <Link href={Route.SignUp} className="text-sm text-primary underline">
          Sign up
        </Link>
      </div>

      <div className="mt-[1000px] flex justify-center gap-2">
        <Link
          href="https://jamloop.com/privacy-policy/"
          className="text-sm text-primary underline"
          target="_blank"
        >
          Privacy Policy
        </Link>
      </div>
    </form>
  );
};

export default SignInEmailForm;
