// Sorted alphabetically

export enum Route {
  AccountSetup = '/account-setup',
  Advertisers = '/advertisers',
  AdvertisersReporting = '/reporting/advertisers',
  Attribution = '/reporting/attribution',
  Campaigns = '/campaigns',
  CreateCampaign = '/campaigns/create',
  Creatives = '/creatives',
  Dashboard = '/dashboard',
  Pixels = '/pixels',
  Reporting = '/reporting',
  SignIn = '/sign-in',
  FactorOne = '/sign-in/factor-one',
  SignUp = '/sign-up',
  Users = '/users',
  Welcome = '/welcome',
}
