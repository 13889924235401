import type { WindowWithClerk } from '@/types/clerk';
import type { LocationType } from '@/utils/locationClassifier';

export const getBaseUrl = () => {
  if (process.env.NEXT_PUBLIC_APP_URL) {
    return process.env.NEXT_PUBLIC_APP_URL;
  }

  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  return 'http://localhost:3000';
};

export const getSessionToken = async () => {
  if (!(window as WindowWithClerk).Clerk?.session) return null;
  return (
    (await (window as WindowWithClerk)?.Clerk?.session?.getToken()) ?? null
  );
};

export const validateZipCode = (zip: string): boolean => {
  return /^\d{5}$/.test(zip.trim());
};

export const getLocationType = (location: string): LocationType => {
  if (location.includes(' DMA')) return 'DMA';
  if (/^[A-Z]{2}$/.test(location)) return 'STATE';
  if (validateZipCode(location)) return 'ZIPCODE';
  return 'UNKNOWN';
};

export const cleanValue = (value: string | null | undefined) => {
  return (
    value
      ?.replace(/"\[\]"/g, '')
      .replace(/[[\]']/g, '')
      .trim() || ''
  );
};

export const clearArray = (array: string[]) => {
  return array.filter((item) => item !== '');
};
