'use client';

import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import { Route } from '@/constants/routes';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const router = useRouter();

  useEffect(() => {
    Sentry.captureException(error);
    console.error('error caught: ', error);
  }, [error]);

  return (
    <div className="relative flex min-h-screen items-center justify-center bg-base-200">
      <div className="absolute inset-0 flex items-center justify-center">
        <h1 className="select-none text-[34rem] font-extrabold text-gray-300 opacity-30">
          500
        </h1>
      </div>

      <div className="card z-10 w-full max-w-md bg-base-100 shadow-xl">
        <div className="card-body text-center">
          <h2 className="card-title justify-center text-error">
            Unexpected Error
          </h2>
          <p className="text-gray-600">
            An unexpected error has occurred. Please try reloading the page or
            return to the dashboard.
          </p>
          <div className="card-actions mt-4 justify-center">
            <button
              className="btn btn-outline"
              type="button"
              onClick={() => router.push(Route.Dashboard)}
            >
              Go to Dashboard
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => reset()}
            >
              Reload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
